import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button } from '../library/buttons/Button';

export const LoginCallbackErrorComponent = (props: { error: Error }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <p style={{ fontSize: '24px', marginBottom: '20px' }}>
        {intl.formatMessage({ id: 'auth.login.error' })}
      </p>
      {props.error && (
        <p style={{ fontSize: '14px', marginBottom: '20px' }}>{props.error.message}</p>
      )}
      <Button
        onClick={() => {
          navigate('/');
        }}
      >
        {intl.formatMessage({ id: 'auth.back.link' })}
      </Button>
    </div>
  );
};
