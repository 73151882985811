import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { ItemSetTemplate, ItemSetTemplateForm } from '../../types/item-set/item-set-template';

const ITEMS_SET_TEMPLATE_URL = '/item-set-template';
const ITEMS_SET_TEMPLATES_URL = '/item-set-templates';
const ITEM_SET_TEMPLATE_IMAGE_QUERY_KEY = 'item-set-template-image';

function useItemSetTemplates() {
  const client = useClient<ItemSetTemplate[]>();
  const result = useQuery('item-set-templates', () => client(ITEMS_SET_TEMPLATES_URL));
  return { ...result, itemSetTemplates: result.data?.data ?? [] };
}

function useCreateItemSetTemplate() {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    (data: ItemSetTemplateForm) =>
      client(`${ITEMS_SET_TEMPLATES_URL}`, {
        method: 'POST',
        data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('item-set-templates');
      },
    }
  );
}
const OneDayInMs = 1000 * 60 * 60 * 24;
export function useItemSetTemplateImage(itemSetTemplateId: string | undefined) {
  const client = useClient<Blob>();
  const result = useQuery(
    [ITEM_SET_TEMPLATE_IMAGE_QUERY_KEY, itemSetTemplateId],
    async () => {
      if (!itemSetTemplateId) throw new Error('itemSetTemplateId is required');
      const response = await client(`${ITEMS_SET_TEMPLATE_URL}/${itemSetTemplateId}/image`, {
        responseType: 'blob',
      });
      return response;
    },
    {
      enabled: !!itemSetTemplateId,
      select: response => {
        return response.status == 200 ? response.data : undefined;
      },
      staleTime: OneDayInMs,
    }
  );
  const imageUrl = useMemo(() => {
    return result.data ? URL.createObjectURL(result.data) : undefined;
  }, [result.data]);
  useEffect(() => {
    return () => {
      if (imageUrl) URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);
  return { ...result, imageUrl: imageUrl };
}

function useDeleteItemSetTemplate() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ _id }: { _id: string }) =>
      client(`${ITEMS_SET_TEMPLATE_URL}/${_id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('item-set-templates');
      },
    }
  );
}

export { useItemSetTemplates, useCreateItemSetTemplate, useDeleteItemSetTemplate };
